<template>
  <div class="dealer">
    <img :src="data.url + 'dealer/bg_1.jpg'" alt="" class="bg1" />
    <img :src="data.url + 'dealer/bg_2.jpg'" alt="" class="bg2" />
    <div class="content">
      <img :src="data.url + 'dealer/title.png'" alt="" class="title" />
      <div class="rowBox" @click="jumpPath('chuangxing')">
        <div class="wrap">
          <div class="light"></div>
          <div class="imgContent">
            <img :src="data.url + 'dealer/menu1.png'" alt="" class="menu" />
            <img :src="data.url + 'dealer/tuan.png'" alt="" class="tuan" />
          </div>
        </div>
      </div>
      <div class="rowBox" @click="jumpPath('nuStar')">
        <div class="wrap">
          <div class="light"></div>
          <div class="imgContent">
            <img :src="data.url + 'dealer/menu3.png'" alt="" class="menu" />
          </div>
        </div>
      </div>
      <div class="rowBox" @click="jumpPath('expo')">
        <div class="wrap">
          <div class="light"></div>
          <div class="imgContent">
            <img :src="data.url + 'dealer/tuan.png'" alt="" class="tuan" />
            <img :src="data.url + 'dealer/menu6.png'" alt="" class="menu" />
          </div>
        </div>
      </div>
    </div>
    <div class="backBtn" @click="backIndex">
      <img :src="data.url + 'backBtn.png'" alt="" />
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
// import { prviewVideoBox } from "../js/until";
import { reactive } from "vue";
import { showToast } from "vant";
// import { baiduTotal } from "../js/common.js";
let data = reactive({
  url: "https://yunyuan-oss.oss-cn-hangzhou.aliyuncs.com/ruxin/", //图片地址
});
const router = useRouter();
//返回首页
let backIndex = () => {
  router.replace("/index");
};
//页面跳转
let jumpPath = (type) => {
  if (!type) {
    showToast("敬请期待");
    return;
  }
  router.push({
    path: `/${type}`,
  });
};
</script>

<style lang="scss" scoped>
.dealer {
  width: 100%;
  height: 100%;
  position: relative;
  background: #4b6794;
  box-sizing: border-box;
  overflow-y: auto;
  .bg1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .bg2 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
  }
  .content {
    position: relative;
    width: 3.5rem;
    margin: 1.2rem auto 0;
    z-index: 10;
    text-align: center;
    .title {
      width: 3.2rem;
    }
    .rowBox {
      width: 100%;
      height: 0.8rem;
      border-radius: 0.1rem;
      border: 1px solid rgba($color: #fff, $alpha: 0.3);
      padding: 0.04rem;
      box-sizing: border-box;
      box-shadow: 0.02rem 0.06rem 0.16rem rgba($color: #bedfec, $alpha: 0.3);
      margin-bottom: 0.12rem;
      .wrap {
        position: relative;
        border-radius: 0.1rem;
        box-sizing: border-box;
        overflow: hidden;
        padding: 2px;
        background: linear-gradient(
          60deg,
          rgba(255, 255, 255, 0.3),
          rgba(255, 255, 255, 0.6)
        );
        .light {
          position: absolute;
          width: 50%;
          height: 1rem;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          border-top: 0.02rem solid;
          border-image: radial-gradient(
              at top center,
              rgba(228, 245, 252, 1),
              rgba(228, 245, 252, 0),
              rgba(228, 245, 252, 0)
            )
            2 2 2 2;
        }
        .imgContent {
          position: relative;
          width: 100%;
          height: 100%;
          border-radius: 0.08rem;
          background: linear-gradient(
              -14deg,
              rgba(122, 189, 228, 0.5),
              rgba(122, 189, 228, 0),
              rgba(122, 189, 228, 0)
            ),
            radial-gradient(
              at top center,
              rgba(197, 234, 255, 0.1),
              rgba(197, 234, 255, 0)
            ),
            #4c6895;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 0;
          .tuan {
            position: absolute;
            left: 0;
            top: 0.1rem;
            width: 1.14rem;
          }
          .menu {
            height: 0.4rem;
          }
          .wait {
            width: 0.48rem;
          }
        }
      }
    }
    .col {
      width: 1.72rem;
    }
    .flexBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-shadow: none;
    }
    .waitShow {
      opacity: 0.6;
    }
  }
  .backBtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 1.17rem;
    height: 1.28rem;
    font-size: 0;
    z-index: 10;
    img {
      width: 100%;
    }
  }
}
</style>
